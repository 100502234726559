<div class="sar-contentBox">
    <div class="content-top d-flex j-c-s">
        <h1><a class="c-left" routerLink="/sar"><span class="fa fa-fw fa-angle-left"></span></a>Stock Authorization
            Request {{requestDetails.trackingNumber}}</h1>
    </div>


    <section>
        <div class="d-flex mt-20">
            <button kendoButton [ngClass]="{'default-button draft':true,'draftbttn':displayedStatus === 'Draft', 'rejectedbttn':displayedStatus === 'Rejected',
            'completedbttn':displayedStatus === 'Completed','cancelledbttn':displayedStatus === 'Cancelled'}">
                {{displayedStatus}} </button>
            <p class=" m-0 ml-20 ">Updated at <i> {{modifiedDateTime | date: dateFormatWithTime}} by <b>
                        {{requestDetails.createdByFullName}}</b></i></p>
        </div>
        <div class="details-box mt-20">
            <!-- info 1  -->
            <div class="info-1">
                <div class="segment-name">
                    <div>{{requestDetails.tittle}}</div>
                </div>
                <div class="segment-data">
                    <p> Priority</p>
                    <h2 class="font-size-s"> Priority {{requestDetails.priorityId}} - {{requestDetails.priorityName}}
                    </h2>
                </div>
                <div class="segment-data">
                    <p> SAR Owner</p>
                    <h2 class="font-size-s">{{requestDetails.ownerName}}</h2>
                </div>

            </div>
            <!-- info 2  segment-data-->
            <div class="info-2">
                <div class="segment-data">
                    <p> Asset </p>
                    <h2 class="font-size-s"> {{requestDetails.assetName}}</h2>
                </div>
                <div class="segment-data">
                    <p> SAR Created By</p>
                    <h2 class="font-size-s"> {{requestDetails.createdByFullName}}</h2>
                </div>
                <div class="segment-data">
                    <p> SAR Created on</p>
                    <h2 class="font-size-s">{{createdDateTime | date: dateFormatWithTime}}</h2>
                </div>

            </div>
            <!-- info 3  justification-details-data-->
            <div class="info-3">
                <div class="justification-details">
                    <div class="head">
                        <p class="m-0">JUSTIFICATION DETAILS</p>
                    </div>
                    <div class="body">
                        <p> {{requestDetails.justification}}</p>
                    </div>
                </div>
            </div>
            <!-- info 4  button-->
            <div *ngIf="accessForEdit()" class="info-4  ml-20 mr-20 mt-20">
                <button kendoButton class="primary-button w-100" (click)="manage(false)"><i
                        class="fa fa-fw fa-angle-left"></i>
                    Edit Header </button>
            </div>
        </div>
    </section>
    <!-- Grid -->
    <div class="d-flex j-c-s mt-20">
        <div>
            <h1>Actions</h1>
        </div>
        <div>
            <button kendoButton class="primary-button" *ngIf="accessForCreateAction()"  (click)="navAddNewAction(true)">Add New Action</button>
        </div>
    </div>
    <section class="grid-box mt-10" kendoTooltip filter="title-tool">

        <kendo-grid [kendoGridBinding]="gridData" #grid="kendoGrid" [pageSize]="20" [resizable]="true"
            [pageable]="true">
            <kendo-grid-column title="Action #" [width]="100">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    {{dataItem.issueNumber}}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Action" [width]="250">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <button kendoButton class="default-button draft border-left"
                        (click)="navViewAction(dataItem,rowIndex)">
                        {{dataItem.actionName | uppercase}}
                    </button>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="changeType" title="Change type" [width]="250"></kendo-grid-column>
            <kendo-grid-column field="totalLines" title="Total Lines " [width]="100"></kendo-grid-column>
            <kendo-grid-column field="status" class="status-column" [width]="250">
                <ng-template class="status-column" kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
                    Status
                    <span kendoTooltip tooltipClass="tooltip-design" id="info"
                        title="* indicates that one or more  line items are in Draft state for the action"
                        class="fa fa-question-circle primary-color title-tool"></span>

                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <button kendoButton [ngClass]="{'default-button draft':true,'draftbttn':dataItem.status === 'Draft', 'rejectedbttn':dataItem.status === 'Rejected',
                'completedbttn':dataItem.status === 'Completed','cancelledbttn':dataItem.status === 'Cancelled'}">
                        {{dataItem.status}} </button><span class="draft-star"
                        *ngIf="dataItem.status !== 'Draft' && dataItem.haveDraftLineItems===true">*</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [width]="50">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <a class="pencil-style" *ngIf="accessForEditAction(dataItem)"  (click)="navAction(dataItem,rowIndex)"><i
                            class="fa fa-pencil font-size-base" aria-hidden="true"></i></a>
                </ng-template>
            </kendo-grid-column>
        </kendo-grid>
    </section>


</div>