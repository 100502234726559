import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/core/services/common.service';
import { NewActionInputParam } from '../../../models/common/new-action-input-param.model';
import { ApprovalDetailService } from '../../../services/approvalDetail/approval-details.service';
import { MMDActionDto } from '../../../models/approval-details/mmd-approval-caction.mode';
import { StockApproverActionDto } from '../../../models/approval-details/stock-approver-action.model';
import { ApprovalMMDActionDetail, ApprovalSAActionDetail } from '../../../models/approval-details/action-detail.model';
import { requestConst } from 'src/app/core/constants/request.const';

@Component({
  selector: 'app-view-requestor-action',
  templateUrl: './view-requestor-action.component.html',
  styleUrls: ['./view-requestor-action.component.scss']
})
export class ViewRequestorActionComponent implements OnInit {
  newActionInputParam: NewActionInputParam = {};
  actionName: string = '';
  actionNameForHeader: string = '';
  dummyInput: number = 0;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
    public approvalDetailService: ApprovalDetailService) {
    const stateData: any = this.router.getCurrentNavigation()?.extras.state;
    if (!this.commonService.isNullOrEmptyOrUndefined(stateData)) {
      this.newActionInputParam.isNew = stateData['isNew'];
      this.newActionInputParam.requestId = stateData['requestId'];
      this.newActionInputParam.requestNumber = stateData['requestNumber'];
      this.newActionInputParam.requestIssueId = stateData['requestIssueId'];
      this.newActionInputParam.changeType = stateData['changeType'];
      this.newActionInputParam.actionNumber = stateData['actionNumber'];
      this.newActionInputParam.assetName = stateData['assetName'];
      this.actionName = stateData['actionName'];
      sessionStorage.setItem('sarnewaction', JSON.stringify(stateData));
    }
    else {
      let sessionState = JSON.parse(sessionStorage.getItem('sarnewaction')!);
      if (!this.commonService.isNullOrEmptyOrUndefined(sessionState)) {
        this.newActionInputParam.isNew = sessionState.isNew;
        this.newActionInputParam.requestId = sessionState.requestId;
        this.newActionInputParam.requestNumber = sessionState.requestNumber;
        this.newActionInputParam.requestIssueId = sessionState.requestIssueId;
        this.newActionInputParam.changeType = sessionState.changeType;
        this.newActionInputParam.actionNumber = sessionState.actionNumber;
        this.newActionInputParam.assetName = sessionState.assetName;
        this.actionName = sessionState.actionName;
      }
    }
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {

  }
  navBack() {
    let changeType:string = this.newActionInputParam.changeType ? this.newActionInputParam.changeType.replace(/ /g,"_") : '';
    this.router.navigate([
      `../${requestConst.approvalDetail}/${this.newActionInputParam.requestId}/${this.newActionInputParam.requestNumber}/${this.newActionInputParam.requestIssueId}/${this.newActionInputParam.actionNumber}/${changeType}`], 
      { state: {
        requestId: this.newActionInputParam.requestId, requestNumber: this.newActionInputParam.requestNumber,
        requestIssueId: this.newActionInputParam.requestIssueId, actionNumber: this.newActionInputParam.actionNumber,
        assetName: this.newActionInputParam.assetName,
        changeType: this.newActionInputParam.changeType
      }, relativeTo: this.route
    });
  }

  mmdApprovalAction(res: ApprovalMMDActionDetail) {
    let mmdAction = this.getmmdAction(res);
    this.mmdAction(mmdAction);
  }

  saApprovalAction(res: ApprovalSAActionDetail) {
    let saAction = this.getsaAction(res);
    this.saAction(saAction);
  }

  getmmdAction(res: ApprovalMMDActionDetail) {
    let mmdAction: MMDActionDto = new MMDActionDto();
    mmdAction.comment = res.comment;
    mmdAction.createdBy = this.commonService.getUserEmailId();
    mmdAction.enrichedDescription = res.enrichedDescription;
    mmdAction.materialNumber = res.materialNumber;
    mmdAction.shortDescription = res.shortDescription;
    mmdAction.modifiedBy = this.commonService.getUserEmailId();
    mmdAction.requestIssueId = res.requestIssueId
    mmdAction.requestIssueTypeId = res.requestIssueTypeId
    mmdAction.requestLineId = res.requestLineId;
    mmdAction.MMDAction = res.mmdAction;
    mmdAction.requestId = res.requestId;
    mmdAction.isEditLineItem = res.isEditLineItem;
    return mmdAction;
  }

  getsaAction(res: ApprovalSAActionDetail) {
    let saAction: StockApproverActionDto = new StockApproverActionDto();
    saAction.comment = res.comment;
    saAction.createdBy = this.commonService.getUserEmailId();
    saAction.modifiedBy = this.commonService.getUserEmailId();
    saAction.requestIssueId = res.requestIssueId!;
    saAction.requestLineId = res.requestLineId;
    saAction.stockApproverAction = res.stockApproverAction;
    saAction.assignedGroupId = res.assignedGroupId!;
    saAction.assignedUserId = res.assignedUserId!;
    saAction.requestId = res.requestId;
    return saAction;
  }

  mmdBulkApprovalAction(res: any) {
    let mMDActionDto: MMDActionDto[] = [];
    for (let mmdAction of res) {
      mMDActionDto.push(this.getmmdAction(mmdAction))
    }
    this.mmdBulkAction(mMDActionDto);
  }

  saBulkApprovalAction(res: any) {
    let stockApproverActionDto: StockApproverActionDto[] = [];
    for (let saAction of res) {
      stockApproverActionDto.push(this.getsaAction(saAction))
    }
    this.saBulkAction(stockApproverActionDto)
  }

  mmdAction(mmdAction: MMDActionDto) {
    this.approvalDetailService.mmdAction(mmdAction).subscribe((response: any) => {
      this.commonService.showNotificationMessage('Status has been sucessfully changed', 'success', 'right')
      this.dummyInput = this.dummyInput + 1;

    });
  }

  mmdBulkAction(mmdAction: MMDActionDto[]) {
    this.approvalDetailService.bulkmmdAction(mmdAction).subscribe((response: any) => {
      this.commonService.showNotificationMessage('Status has been sucessfully changed ', 'success', 'right');
      this.dummyInput = this.dummyInput + 1;
    });
  }

  saAction(saAction: StockApproverActionDto) {
    this.approvalDetailService.saAction(saAction).subscribe((response: any) => {
      this.commonService.showNotificationMessage('Status has been sucessfully changed ', 'success', 'right')
      this.dummyInput = this.dummyInput + 1;
    });
  }

  saBulkAction(saAction: StockApproverActionDto[]) {
    this.approvalDetailService.bulksaAction(saAction).subscribe((response: any) => {
      this.commonService.showNotificationMessage('Status has been sucessfully changed', 'success', 'right')
      this.dummyInput = this.dummyInput + 1;
    });
  }
}
