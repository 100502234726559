<!-- Create component -->
<div class="sar-contentBox">
    <div class="content-top d-flex j-c-s">
        <h1><a class="c-left"><span class="fa fa-fw fa-angle-left" (click)="navBack()"></span></a>{{headerText}}</h1>
        <div>
            <button kendoButton class="border-button ml-20 f-size-150" routerLink="/sar"> Cancel </button>
            <button kendoButton class="primary-button ml-20 f-size-150" (click)="save()"
                [disabled]="saveDisabled">
                {{manageAction}}
            </button>
        </div>

    </div>
    <section>
        <div class="text-box">
            <div class="mt-10">
                <kendo-label text="Title"><span style="color:red"> *</span></kendo-label>
                <br>
                <kendo-textbox [maxlength]="200" class="mt-5" placeholder="Enter a suitable title for SAR"
                    [(ngModel)]="SARrequest.tittle"></kendo-textbox>
            </div>
            <div class="mt-10 ml-20">
                <!-- Drop down -->
                <kendo-label text="Priority"><span style="color:red"> *</span></kendo-label>
                <select class="c-select" [(ngModel)]="SARrequest.priority">
                    <option *ngFor="let priority of priorityList" [value]="priority.id">{{priority.label}}</option>
                </select>
            </div>
        </div>
    </section>
    <p>Character count: {{SARrequest.tittle!.length|json}}</p>
    <section class="mt-10 ">
        <div class="w-75">
            <kendo-label text="Request Justification"><span style="color:red"> *</span></kendo-label>
            <br>
            <kendo-textarea [rows]="5" placeholder="Please enter the Justification for SAR"
                [(ngModel)]="SARrequest.justification" [maxlength]="250"></kendo-textarea>
            <p>Character count: {{SARrequest.justification!.length|json}}</p>
        </div>
        <div class="text-box">
            <div class="mt-10">
                <kendo-label text="AssetID"><span style="color:red"> *</span></kendo-label>
                <select class="c-select" [(ngModel)]="SARrequest.assetId">
                    <option *ngFor="let asset of assets$ | async" [value]="asset.id">{{asset.name}}</option>
                </select>
            </div>
            <div class="mt-10 ml-20">
                <kendo-label text="SAR Owner"><span style="color:red"> *</span></kendo-label>
                <select class="c-select" [(ngModel)]="SARrequest.ownerId">
                    <option *ngFor="let owner of SAROwnersByBUList" [value]="owner.id">{{owner.name}}</option>
                </select>
            </div>
        </div>
    </section>

</div>